import { render, staticRenderFns } from "./ai-bot-message.vue?vue&type=template&id=436cfced&scoped=true&"
import script from "./ai-bot-message.vue?vue&type=script&lang=js&"
export * from "./ai-bot-message.vue?vue&type=script&lang=js&"
import style0 from "./ai-bot-message.vue?vue&type=style&index=0&id=436cfced&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "436cfced",
  null
  
)

export default component.exports